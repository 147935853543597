// Update the database paymentHistory to include the delivery fees that were charged
// to each user.
import firebase from "../../../../../components/Firebase.js";
import YYYYMMDDConverter from "../../../../../pages/BasketPage/Functions/YYYYMMDDConverter.js";

// This is used to list the users with the communityLocations.
function UserCommunityLocations(order) {
  // Dictionary with the key firstName-lastName-userId : communityLocation
  const communityMembers = {};
  // cycle through all the community Orders.
  Object.keys(order.communityOrders).forEach((user) => {
    // If the user has a communitypickup.
    if (order.communityOrders[user].communityPickup != null) {
      // If the communityPickup has a locationName.
      if (order.communityOrders[user].communityPickup.locationName != null) {
        // THe communityOrders includes the time of the order so we want to remove that.
        const userIdentifier = [
          user.split("-")[0],
          user.split("-")[1],
          user.split("-")[2],
        ].join("-");
        // Set the communityLocation if it exists.
        communityMembers[userIdentifier] =
          order.communityOrders[user].communityPickup.locationName;
      }
    }
  });
  return communityMembers;
}

export default function UpdatePaymentHistoryDeliveryFee(
  paymentHistoryToUpdate,
  orderDate,
  updatePaymentHistory,
  order,
) {
  // set up the datebase.
  const database = firebase.firestore();

  // Set the list of community members and the communityLocations.
  const communityPickupLocations = UserCommunityLocations(order);

  // This is the dictionary that is stored in the database in the payment history
  // that we will update.
  let orderDatePaymentHistory = {};
  // A field that has the orderDate with "Exists" concatenated to the end to help
  // with searching for the document in the database.
  const orderExistsField = orderDate.concat("Exists");

  // Find the document that has the payment history that we are applying for.
  const paymentHistoryDocRef = database
    .collection("PaymentHistory")
    .where(orderExistsField, "==", true);

  // Pull the document and find the matching document.
  paymentHistoryDocRef
    .get()
    .then((collection) => {
      collection.docs.forEach((doc) => {
        if (doc.exists) {
          // Find the document's name by reading the createdDate variable which is
          // the same.
          const documentName = doc.data().createdDate;
          // Run a transaction since payment can be updated by adding or subtracting values based off
          // the value stored in the database we have to make sure that the value is up
          // to date and accurate.
          return database.runTransaction((transaction) => {
            // Store the document's reference.
            const paymentHistoryDocRef = database
              .collection("PaymentHistory")
              .doc(documentName);

            // This code may get re-run multiple times if there are conflicts.
            return transaction
              .get(paymentHistoryDocRef)
              .then((paymentHistoryDoc) => {
                if (!paymentHistoryDoc.exists) {
                  // eslint-disable-next-line no-throw-literal
                  throw "Document does not exist!";
                }

                // Load the full document of this order Date.
                const documentData = paymentHistoryDoc.data();

                const index = Object.keys(doc.data()).indexOf(orderDate);
                // The order exists in this document.
                if (index !== -1) {
                  // Store the current orderDate.
                  orderDatePaymentHistory = doc.data()[orderDate];

                  // Store the communityOrders of this date.
                  let communityOrdersPaymentHistory = {};

                  // The dictionary that saves the community orders payment history.  This is used
                  // later and added into the larger payment history that is saved to the database.  This
                  // is specifically for the community location's orders not their imported orders.
                  communityOrdersPaymentHistory =
                    orderDatePaymentHistory.communityOrders;

                  // The current time to record the payment change.
                  const now = YYYYMMDDConverter();

                  // Cycle through all the users to update in payment history.
                  Object.keys(paymentHistoryToUpdate).forEach((user) => {
                    // The delivery fee charged to the user.  There may be a subsidy
                    // adjustment in this if the user's subsidy was greater than the order total
                    // due to the cost of their order going down since they placed it.  It may also
                    // include a previous delivery fee that was added which will offset the value of this
                    // amount.
                    const deliveryFeePlusSubsidyAdjustment =
                      paymentHistoryToUpdate[user];
                    // Set the new orders amount to their old amount.
                    communityOrdersPaymentHistory[user].totalOwed += parseFloat(
                      deliveryFeePlusSubsidyAdjustment,
                    );
                    // Add this new order's total and date to the orderHistory.
                    communityOrdersPaymentHistory[user].orderHistory[now] =
                      communityOrdersPaymentHistory[user].totalOwed;
                    // Update the notes to inlcude the additional order added.
                    communityOrdersPaymentHistory[user].orderNotes =
                      communityOrdersPaymentHistory[user].orderNotes.concat(
                        `Added the delivery fee $${deliveryFeePlusSubsidyAdjustment}. `,
                      );
                    // If the community member has a special community pick up then
                    // set it.
                    if (communityPickupLocations[user] != null) {
                      communityOrdersPaymentHistory[user].communityLocation =
                        communityPickupLocations[user];
                    } else {
                      communityOrdersPaymentHistory[user].communityLocation =
                        "";
                    }
                  });

                  // Update the payment history to include the community orders.
                  orderDatePaymentHistory.communityOrders =
                    communityOrdersPaymentHistory;
                }
                // This order doesn't exist in this document so you can't update it.
                else {
                  console.log("This order cannot be updated.");
                }

                // Update the full documentName to include the changes for this date.
                documentData[orderDate] = orderDatePaymentHistory;

                // Complete the transaction.  If the document has been changed then
                // this document has to go again.
                transaction.update(paymentHistoryDocRef, documentData);
                // Update the paymentHistory dictionary
                updatePaymentHistory(documentData, orderDate);
              });
          });
        } else {
          console.log("UNDEFINED");
        }
      });
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });
}
