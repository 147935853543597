// This function is called to add credits to the volunteer accounts
// and to update the database.
import AddUserCredits from "../../../../../pages/MyAccountPage/Functions/VolunteerSchedule/AddUserCredits.js";
import firebase from "../../../../Firebase.js";

export default function UpdateCredits(
  userIdList,
  volunteerSchedule,
  setVolunteerSchedule,
  selectedPosition,
  date,
  timePeriod,
) {
  // volunteerSchedule is an array with each array containing a timePeriod
  // We need to know which index is the one that has the timePeriod we are
  // looking to update.
  const volunteerScheduleIndex = volunteerSchedule.findIndex(
    (entry) => entry.timePeriod === timePeriod,
  );
  // Create a temp volunteerSchedule.
  const volunteerScheduleTemp = [...volunteerSchedule];

  const database = firebase.firestore();
  // Sets the Users update
  const batch = database.batch();
  // Cycle throught the userIdList which is the {userId : "shift"}
  userIdList.forEach((userShift) => {
    const userId = Object.keys(userShift)[0]; // Get the key
    const shift = userShift[userId]; // Get the value
    // Add the current user to the entered codes list so we don't send them the
    // credits multiple times.  First you have to make sure that enteredCodes exists.
    if (
      volunteerScheduleTemp[volunteerScheduleIndex][date][selectedPosition][
        shift
      ].enteredCodes !== undefined
    ) {
      volunteerScheduleTemp[volunteerScheduleIndex][date][selectedPosition][
        shift
      ].enteredCodes.push(userId);
    } else {
      volunteerScheduleTemp[volunteerScheduleIndex][date][selectedPosition][
        shift
      ].enteredCodes = [userId];
    }

    // Add the credits to the user's profile
    AddUserCredits(
      volunteerSchedule[volunteerScheduleIndex][date][selectedPosition]
        .creditValue,
      userId,
    );
  });

  // update the volunteer schedule.
  const volunteerScheduleDocRef = database
    .collection("VolunteerSchedule")
    .doc(timePeriod);
  // commits and updates the database.
  batch.update(volunteerScheduleDocRef, {
    ...volunteerScheduleTemp[volunteerScheduleIndex],
  });

  batch.commit();
  setVolunteerSchedule([...volunteerScheduleTemp]);
}
