// This will create the message to send to the volunteers that includes their custom
// credit code, date, shift completed and thank you.
export default function CreateCreditSendMessage(
  setCreditsMessage,
  volunteers,
  selectedPosition,
  date,
  volunteerEmails,
) {
  // The months in human redable format
  const months = [
    "Jan.",
    "Feb.",
    "Mar.",
    "Apr.",
    "May",
    "Jun.",
    "Jul.",
    "Aug.",
    "Sept.",
    "Oct.",
    "Nov.",
    "Dec.",
  ];
  // Set the date to the month and the day rather than the array position.
  const dateHumanFormat = [
    months[parseInt(date.substr(0, 2))],
    date.substr(2, 2),
  ].join(" ");

  // We have some custom acknowledgement messages for some volunteer positions.
  const acknowledgementMessage = {
    "Community Host Private":
      "Thank you for providing your home to your community.",
    "Community Host Public":
      "Thank you for helping distribute food to your community.",
    "Order Helper": "Thank you for helping your community member order.",
    Packaging: "Thank you for helping package your community's orders.",
  };
  // The dictionary that will store each user's message.
  const creditsMessageTemp = {};

  // This is the first message the user reads. It's a thank you with the custom
  // message above or a generic one if one doesn't exist.
  let openingThankYou = acknowledgementMessage[selectedPosition];
  if (openingThankYou === undefined) {
    openingThankYou =
      "Thank you for serving your community and helping build a stronger local food system.";
  }

  // Cycle through the volunteerEmails which has the shifts and then the user's names
  // in the firstName-lastName-userId format we want.
  Object.keys(volunteerEmails).forEach((shift) => {
    // Cycle through all the volunteers
    Object.keys(volunteerEmails[shift]).forEach((volunteer) => {
      // Create the message for the vounteer.
      creditsMessageTemp[
        volunteer
      ] = `${openingThankYou}<br/><br/>Each time you complete a volunteer shift we will send you an email to confirm your credits earned.
        <br/><br/>You earned ${volunteers.creditValue} credits for your ${dateHumanFormat} ${selectedPosition} volunteering.  The credits 
        are automatically added and can be view by heading to My Account -> Community -> Volunteering.
        You will see all the shifts you are scheduled for.
        <br/><br/>We will decide as a community what to do with leftover credits in the future.
        <br/><br/>You can also export the calendar so that it is in your phone by clicking "EXPORT CALENDAR" on a mobile phone.
        <br/><br/>Please let us know if you have any questions.
        <br/><br/>Thank you,<br/>Farm-to-Plate Marketplace Team`;
    });
  });

  setCreditsMessage(creditsMessageTemp);
}
