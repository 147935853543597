// This is used to transfer an order between locations on a given day.  It can transfer
// them between community hubs as well as imported locations.  It also transfer the payment
// history.
import React, { useState, useEffect, useContext } from "react";
import firebase from "../../../components/Firebase.js";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CopyOrdersBetweenLocations from "../Functions/CopyOrdersBetweenLocations.js";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import IconButton from "@material-ui/core/IconButton";
import EastIcon from "@mui/icons-material/East";
import SimpleSelect from "../../../components/userInputs/DropDownMenu.js";
import LoadingContent from "../../../components/LoadingContent.jsx";
import { CommunityOrderChunksContext } from "../../../pages/MyAccount.js";

export default function CopyOrdersBetweenLocationsDialog({
  distributionLocation,
  orderDate,
  userOrderIdentifier,
  imported,
  distributionDate,
  order,
  userInfo,
  orderLog,
  orderIndex,
  updateOrderLog,
  updatePaymentHistory,
}) {
  const [open, setOpen] = useState(false);
  // This is the pick up location that the user has selected.  It can be a distribution location
  // imported or not, or a community hub imported or not.
  const [pickupLocationToUpdate, setPickupLocationToUpdate] = useState("");

  // This is a dictionary of all the locations.  Each location is an array with either 2-3 items.
  // It will be [imported(boolean), selectedDate, communityLocationInfo(if communityHub)]
  const [listOfLocations, setListOfLocations] = useState("loading");

  // This is the date of distribution.  If the user is from an imported order then it will
  // be updated to that distribution location's distribution date in the useEffect.  It is set
  // to this location's distribution date by default.
  const [distributionDateOriginal, setDistributionDateOriginal] =
    useState(distributionDate);

  // This is the original pickupLocation.  It might change if the user is using a communty hub
  // and it's not the distribution location.
  const [pickupLocation, setPickupLocation] = useState(distributionLocation);

  // communityOrderChunks are in {orderdate: [communityOrders, overFlowCommunityOrders1, etc.]}
  const { communityOrderChunks = [], setCommunityOrderChunks = () => {} } =
    useContext(CommunityOrderChunksContext) || {};

  // The first useEffect is to check that the pickupLocation is set correctly in case the user
  // is using a community hub and so we need to update it.
  useEffect(() => {
    if (imported) {
      if (
        !(
          order.importedOrder[distributionLocation].communityOrders[
            userOrderIdentifier
          ].communityPickup.length === 0
        )
      ) {
        setPickupLocation(
          order.importedOrder[distributionLocation].communityOrders[
            userOrderIdentifier
          ].communityPickup.locationName,
        );
      }
    } else {
      if (
        !(
          order.communityOrders[userOrderIdentifier].communityPickup.length ===
          0
        )
      ) {
        setPickupLocation(
          order.communityOrders[userOrderIdentifier].communityPickup
            .locationName,
        );
      }
    }
  }, []);

  // This useEffect loads all the community hubs into the list so that they can be selected from by the user
  // and updates the distribution original date in case it's a community hub.
  useEffect(() => {
    // If the dialog is open.  We don't want to do this if it's closed because then we will have
    // unnecessary calls to the database.
    if (open) {
      // The database in firebase.
      const database = firebase.firestore();
      // Initialize the list of locations.  We know that the distribution location will be at least one.
      // Set the location to not imported and the order selected date.
      const listOfLocationsTemp = {
        [userInfo.organisationName]: [false, order.selectedDate],
      };

      // If the order has an imported order then we want to pull all their community hubs too.
      if (order.importedOrder) {
        // For each imported distribution location add true for imported and that location's distribution
        // date.
        Object.keys(order.importedOrder).forEach((distributionLocation) => {
          listOfLocationsTemp[distributionLocation] = [
            true,
            order.importedOrder[distributionLocation].date,
          ];
        });
      }

      // Now that we've got all the distribution locations we want to pull all their community hubs.
      const communityHubsDocRef = database
        .collection("CommunityLocations")
        .where(
          "distributionLocationName",
          "in",
          Object.keys(listOfLocationsTemp),
        )
        .orderBy("locationName");

      // Cycle through the community hubs.
      communityHubsDocRef
        .get()
        .then((collection) => {
          collection.docs.forEach((doc) => {
            if (doc.exists) {
              // Set the document data to this variable.
              const communityLocationInfo = doc.data();
              // If the distribution location of the hub doesn't match the current distribution location then we
              // know it's a community hub of an imported distribution locations.
              if (
                communityLocationInfo.distributionLocationName !==
                userInfo.organisationName
              ) {
                // Set the imported location to true and and use the distribution date of the imported location and add the
                // community hub info.
                listOfLocationsTemp[communityLocationInfo.locationName] = [
                  true,
                  order.importedOrder[
                    communityLocationInfo.distributionLocationName
                  ].date,
                  communityLocationInfo,
                ];
              } else {
                // Set the imported location to false and and use the distribution date the current location and add the
                // community hub info.
                listOfLocationsTemp[communityLocationInfo.locationName] = [
                  false,
                  order.selectedDate,
                  communityLocationInfo,
                ];
              }
            } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
            }
          });

          // Add the distribution location's date as it might be different from the default
          // if it's a community hub or an imported order.
          setDistributionDateOriginal(listOfLocationsTemp[pickupLocation][1]);

          // Remove the current location from the list as we don't want to transfer the
          // order to the same location it is already at.
          delete listOfLocationsTemp[pickupLocation];

          // Set the list to contain all the new possible locations.
          setListOfLocations({ ...listOfLocationsTemp });
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
        });
    }
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Save the changes.
  const handleSave = (e) => {
    e.preventDefault();
    // Updates the database in the DistributionLocations, Users order and the payment history.
    CopyOrdersBetweenLocations(
      orderDate,
      userOrderIdentifier,
      pickupLocationToUpdate,
      listOfLocations,
      pickupLocation,
      distributionLocation,
      distributionDateOriginal,
      orderLog,
      orderIndex,
      updateOrderLog,
      updatePaymentHistory,
      communityOrderChunks,
      setCommunityOrderChunks,
    );
    setOpen(false);
  };

  // Checks if the user can click save.  The user must first select a location to save to.
  let canSave = false;

  // Check to make sure that pickupLocationToUpdate is a value that isn't empty or the current location.
  if (
    !!pickupLocationToUpdate &&
    pickupLocationToUpdate !== "" &&
    pickupLocationToUpdate !== pickupLocation
  ) {
    // Set this to true so the user can save
    canSave = true;
  }

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <SwapHorizIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ padding: "16px 24px 0px" }}
        >
          Swap Order Between Locations
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSave}>
            <span>
              {pickupLocation}
              <EastIcon />
              {listOfLocations === "loading" ? (
                <LoadingContent inline={true} />
              ) : (
                <SimpleSelect
                  margin="dense"
                  id="selectOrderStatsLocation"
                  options={Object.keys(listOfLocations)}
                  handleChange={(e) =>
                    setPickupLocationToUpdate(e.target.value)
                  }
                  selected={pickupLocationToUpdate}
                />
              )}
            </span>
            <DialogActions>
              <Button onClick={() => handleClose()} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={!canSave}>
                Save
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
