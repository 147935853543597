// This displays the farmers that are available at a community location.
import React, { useEffect, useState } from "react";
import Chip from "@material-ui/core/Chip";
import { faTractor } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import firebase from "./../../../../components/Firebase.js";

// Remove or add a farm to a community site depending on the user's actions.
function AddOrRemoveFarm(
  communityLocationFarmList,
  setCommunityLocationFarmList,
  handleSave,
  farm,
  addFarm,
) {
  // Set the farmList to the current list.
  const communityLocationFarmListTemp = [...communityLocationFarmList];
  // If we're adding the farm then push it.
  if (addFarm) {
    communityLocationFarmListTemp.push(farm);
  }
  // Otherwise find the index and remove it.
  else {
    // The index of the farm list.
    const indexInFarmList = communityLocationFarmListTemp.findIndex(
      (farmerInList) => {
        return farmerInList.farmName === farm.farmName;
      },
    );
    // Remove the farm from the list.
    communityLocationFarmListTemp.splice(indexInFarmList, 1);
  }
  // Set the farm list.
  setCommunityLocationFarmList([...communityLocationFarmListTemp]);
  // We need to send preventDefault as it's a value that handleSave is expecting.
  // We also have to send the list to be saved.
  handleSave({
    preventDefault: () => {},
    value: [...communityLocationFarmListTemp],
  });
}

export default function FarmChips({
  communityLocation,
  handleSave,
  communityLocationFarmList,
  setCommunityLocationFarmList,
}) {
  // This is the farmList of all the possible active farmers.
  const [farmList, setFarmList] = useState([]);

  useEffect(() => {
    const database = firebase.firestore();

    const dataTransfer = [];
    // Checks the database for ongoing recurring orders under the current user's
    // profile.
    const docRef = database
      .collection("Farmers")
      .where("status", "==", "active");
    // Set the farmList to the existing or to an empty array depending.
    const communityLocationFarmListTemp = [
      ...(communityLocation.communityLocationFarmList || []),
    ];

    docRef
      .get()
      .then((collection) => {
        collection.docs.forEach((doc) => {
          dataTransfer.push(doc.data());
        });
        // if the farmList exists and it's not empty then set to the list to the full
        // farm List.
        if (
          !communityLocationFarmListTemp ||
          communityLocationFarmListTemp.length === 0
        ) {
          setCommunityLocationFarmList([...dataTransfer]);
        }
        // Otherwise read look at the list and fine all the farmers that
        // are still active and in the community hubs list and add them.
        // The community hub might have inactive farmers and we have to remove them.
        else {
          const upToDatecommunityLocationFarmList = [];
          communityLocationFarmListTemp.forEach((farmer) => {
            const indexInFarmList = dataTransfer.findIndex((farmerInList) => {
              return farmerInList.farmName === farmer.farmName;
            });
            if (indexInFarmList !== -1) {
              upToDatecommunityLocationFarmList.push(farmer);
            }
          });
          setCommunityLocationFarmList([...upToDatecommunityLocationFarmList]);
        }
        // Set the farm list to the datatransfer to hold all the farms
        setFarmList([...dataTransfer]);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  }, []);

  return (
    <span>
      <h3 className="Header-3"> Farms Providing to This Location: </h3>
      {communityLocationFarmList.map((farm, i) => (
        <Chip
          key={i}
          color="primary"
          style={{ margin: "5px" }}
          size="small"
          label={farm.farmName}
          icon={
            <FontAwesomeIcon className="w3-hover-opacity" icon={faTractor} />
          }
          onDelete={() =>
            AddOrRemoveFarm(
              communityLocationFarmList,
              setCommunityLocationFarmList,
              handleSave,
              farm,
              false,
            )
          }
        />
      ))}
      <h3 className="Header-3"> Additional Farms Available: </h3>
      {farmList
        .filter(
          (farm) =>
            !communityLocationFarmList.some(
              (selectedFarm) => selectedFarm.farmName === farm.farmName,
            ),
        )
        .map((farm, i) => (
          <Chip
            key={i}
            color="primary"
            style={{ margin: "5px" }}
            size="small"
            label={farm.farmName}
            icon={
              <FontAwesomeIcon className="w3-hover-opacity" icon={faTractor} />
            }
            onDelete={() =>
              AddOrRemoveFarm(
                communityLocationFarmList,
                setCommunityLocationFarmList,
                handleSave,
                farm,
                true,
              )
            }
          />
        ))}
    </span>
  );
}
