// This is a function that creates the overFlow documents needed to be saved in the database to
// make sure that the orderDoc doesn't get too large.
import firebase from "../../../components/Firebase.js";

export default function CreateOverFlowOrderDocument(
  communityOrders,
  existingOrderDate,
  batch,
) {
  const database = firebase.firestore();
  // The maximum number of orders that can go in a document. This is number orders not number of users.
  // That means that if a user has two orders they would count as two orders.
  const maxCommunityOrderCount = 40;
  // This list of chunks with the first 0 being the communityOrders for the orderDoc and all others being the
  // overFlows.
  const communityOrdersList = [];
  // CustomerOrders are the {firstName-lastName-userId : {order}}
  const keys = Object.keys(communityOrders);
  // The total number of orders allowed.
  const totalNumberOfOrders = keys.length;
  // Calculate how many chunks needed to keep the communityOrders under the maxCommunityOrderCount.
  const chunksNeeded = Math.ceil(totalNumberOfOrders / maxCommunityOrderCount);
  // The overFlow is one less as the first one is not overFlow but included in the doc itself.
  const overFlowCount = chunksNeeded - 1;

  // Cycle through the communityOrders and create the chunks.
  for (let i = 0; i < chunksNeeded; i++) {
    const start = i * maxCommunityOrderCount; // Starting index for each chunk
    const end = Math.min(start + maxCommunityOrderCount, totalNumberOfOrders); // Ending index for each chunk
    const chunkKeys = keys.slice(start, end); // Get the keys for this chunk

    const chunk = {}; // Create an object for the current chunk
    chunkKeys.forEach((key) => {
      chunk[key] = communityOrders[key]; // Add key-value pairs to the chunk
    });

    communityOrdersList.push(chunk); // Push the chunk to the list
  }

  // If there are no communityOrders we need to push an empty dictionary
  // to the start of the array.
  if (communityOrdersList.length === 0) {
    communityOrdersList.push({});
  }

  // Now we have to create the firebase docs.
  for (let i = 0; i < overFlowCount; i++) {
    // The overFlowChunks start at 1 since the 0 chunk is in the orderDoc.
    const overFlowChunk = i + 1;
    const overFlowDocumentName = existingOrderDate.concat(overFlowChunk);
    // The reference of the document
    const ordersOverFlowDocRef = database
      .collection("Users")
      .doc(firebase.auth().currentUser.uid)
      .collection("Orders")
      .doc(existingOrderDate)
      .collection("OverFlow")
      .doc(overFlowDocumentName);

    batch.set(ordersOverFlowDocRef, {
      communityOrders: communityOrdersList[overFlowChunk],
    });
  }

  return [overFlowCount, communityOrdersList[0], communityOrdersList];
}
