import { Sema } from "async-sema";

// The semaphore is needed if batchEmails is not awaited and "batchEmails" called multiple times.
// Set to 2, but maybe needs to be 1.
const semaphore = new Sema(2);

const minuteInMs = 60000;

/**
 * batchedParamsForEmailFunc: Items to use as params for the singularEmailFunc.
 * singularEmailFunc: Async function that calls FireBase email cloud functions. Items from "itemsForEmailFunc" must be valid parameters for this function.
 * delayBetweenItems: Delay in milliseconds between batch email items.
 * emailsPerMinute: If defined, only lets batchEmails func send X emails per minute.
 */

// If the email send fails the retry.
async function attemptWithRetry(func, maxRetries) {
  let attempt = 0;

  while (attempt < maxRetries) {
    try {
      return await func(); // Execute the provided function
    } catch (error) {
      attempt++;
      console.error(`Attempt ${attempt} failed. Error: ${error.message}`);
      if (attempt < maxRetries) {
        const backoff = Math.pow(2, attempt) * 500; // Exponential backoff
        console.log(`Retrying after ${backoff} ms...`);
        await new Promise((resolve) => setTimeout(resolve, backoff));
      } else {
        throw new Error(`Max retries reached. Last error: ${error.message}`);
      }
    }
  }
}

export default async function batchEmails({
  batchedParamsForEmailFunc = [],
  singularEmailFunc,
  delayBetweenItems = 100,
  emailsPerMinute = 28,
  maxRetries = 3, // Maximum retry attempts
}) {
  let counter = 0;
  for (const item of batchedParamsForEmailFunc) {
    await semaphore.acquire();
    try {
      await attemptWithRetry(() => singularEmailFunc(item), maxRetries);
    } catch (error) {
      console.error(
        `Failed to process item: ${item} after retries. Error: ${error.message}`,
      );
    }
    await new Promise((resolve) => setTimeout(resolve, delayBetweenItems));

    counter++;
    // Not optimized to work with delay in batches.
    if (counter > emailsPerMinute) {
      counter = counter - emailsPerMinute;
      await new Promise((resolve) => setTimeout(resolve, minuteInMs));
    }

    semaphore.release();
  }
}
