// The volunteer schedule that is listed in the admin user.
import React, { useEffect, useState, createContext } from "react";
import firebase from "../../../../components/Firebase.js";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import AddVolunteerDialog from "../../Dialogs/VolunteerSchedule/AddVolunteerDialog.jsx";
import RemoveVolunteerFromShift from "../../Functions/RemoveVolunteerFromShift.js";
import VolunteerInfoDialog from "../../Dialogs/VolunteerSchedule/VolunteerInfoDialog.jsx";
import VolunteerEmailDialog from "../../../../components/dialogs/Components/SendUserEmails/VolunteerEmailDialog.jsx";

export const VolunteerScheduleContext = createContext();

export default function VolunteerScheduleList({
  volunteerSchedule,
  setVolunteerSchedule,
  volunteerJobs,
}) {
  // The selected position to look at the schedule for.
  const [selectedPosition, setSelectedPosition] = useState("");
  // The displayed schedule that is curated for the position.
  const [displayedSchedule, setDisplayedSchedule] = useState([]);
  // The volunteer list that includes all the users.
  const [volunteerList, setVolunteerList] = useState({});
  // The loading of the volunteer.
  const [volunteerLoading, setVolunteerLoading] = useState(false);
  const database = firebase.firestore();

  // The months in an index.
  const months = [
    "Jan.",
    "Feb.",
    "Mar.",
    "Apr.",
    "May",
    "Jun.",
    "Jul.",
    "Aug.",
    "Sept.",
    "Oct.",
    "Nov.",
    "Dec.",
  ];

  // Show the displayed volunteer schedule.
  useEffect(() => {
    // Create the displayedSchedule that will be array of all the quarters.
    const displayedScheduleTemp = [];
    // Cycle through all the volunteer schedule quarters and then add their
    // dates to the schedule.
    volunteerSchedule.forEach((volunteerScheduleQuarter, index) => {
      // Initialise the quarter to display to be empty
      displayedScheduleTemp[index] = {};
      // Cycle through the volunteer quarters' dates
      Object.keys(volunteerScheduleQuarter).forEach((date) => {
        // Check if the date has any of the selected position.
        if (
          Object.keys(volunteerScheduleQuarter[date]).includes(selectedPosition)
        ) {
          // Initalize first the date
          displayedScheduleTemp[index][date] = {};
          // Plug in the data of this position.
          displayedScheduleTemp[index][date][selectedPosition] =
            volunteerScheduleQuarter[date][selectedPosition];
        }
      });
    });
    setDisplayedSchedule([...displayedScheduleTemp]);
  }, [volunteerSchedule]);

  // Load the volunteer schedule from the database.
  useEffect(() => {
    // Load the volunteer positions from the database and add them to the hook volunteerJobs.
    const volunteerDocRef = database.collection("VolunteerList");
    let volunteerDataTransfer = [];

    volunteerDocRef
      .get()
      .then((collection) => {
        collection.docs.forEach((doc) => {
          if (doc.exists) {
            volunteerDataTransfer = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        });
        setVolunteerList(volunteerDataTransfer);
        setVolunteerLoading(true);
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
        setVolunteerLoading(true);
      });
  }, []);

  // When the user changes the position they're selecting.
  const handlePositionChange = (position) => {
    setSelectedPosition(position);
    // Reset the displayed the schedule.
    const displayedScheduleTemp = [];

    volunteerSchedule.forEach((volunteerScheduleQuarter, index) => {
      displayedScheduleTemp[index] = {};
      // Cycle through the volunteer schedule and add the positions that equal the selected
      // position to the schedule.
      Object.keys(volunteerScheduleQuarter).forEach((date) => {
        // If the position matches the selected position add to the displayed schedule.
        if (Object.keys(volunteerScheduleQuarter[date]).includes(position)) {
          displayedScheduleTemp[index][date] = {};
          displayedScheduleTemp[index][date][position] =
            volunteerScheduleQuarter[date][position];
        }
      });
    });
    setDisplayedSchedule([...displayedScheduleTemp]);
  };

  return (
    <VolunteerScheduleContext.Provider
      value={{
        volunteerSchedule,
        setVolunteerSchedule,
        selectedPosition,
      }}
    >
      <h2>Schedule</h2>
      <TextField
        id="Volunteer-Select-Id"
        margin="dense"
        value={selectedPosition || ""}
        onChange={(e) => handlePositionChange(e.target.value)}
        label="Select a Volunteer Position"
        select
        fullWidth
        required
      >
        {volunteerJobs.map((option, index) => {
          return (
            <MenuItem key={index} value={option.title}>
              {option.title}
            </MenuItem>
          );
        })}
      </TextField>
      {displayedSchedule.map((displayedScheduleQuarter, i) => (
        <>
          {Object.keys(displayedScheduleQuarter)
            .sort()
            .map((date, index) => (
              <div key={index}>
                <span
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <h5>
                    <u>
                      {months[parseInt(date.substr(0, 2))]} {date.substr(2, 2)}{" "}
                      {volunteerSchedule[i].timePeriod.substr(0, 4)}
                    </u>
                  </h5>
                  <VolunteerEmailDialog
                    volunteers={
                      displayedScheduleQuarter[date][selectedPosition]
                    }
                    selectedPosition={selectedPosition}
                    date={date}
                    timePeriod={volunteerSchedule[i].timePeriod}
                  />
                </span>
                <span style={{ display: "flex", gap: "50px" }}>
                  {Object.keys(displayedScheduleQuarter[date][selectedPosition])
                    .filter(
                      (shift) =>
                        shift !== "creditValue" && shift !== "location",
                    )
                    .sort((a, b) => {
                      // Sort the shifts by start time (assuming shift time is in 'hh:mm' format)
                      const timeA = a.slice(-8, -4); // Extract start time of shift A
                      const timeB = b.slice(-8, -4); // Extract start time of shift B
                      return timeA.localeCompare(timeB); // Compare start times
                    })
                    .map((shift, ind) => (
                      <React.Fragment key={ind}>
                        <div>
                          Location:{" "}
                          {
                            displayedScheduleQuarter[date][selectedPosition]
                              .location
                          }{" "}
                          <br />
                          Time: {shift.slice(-8, -4)} to {shift.slice(-4)}{" "}
                          <br />
                          Credits:{" "}
                          {
                            displayedScheduleQuarter[date][selectedPosition]
                              .creditValue
                          }{" "}
                          <br />
                          Volunteer Code:{" "}
                          {
                            displayedScheduleQuarter[date][selectedPosition][
                              shift
                            ].code
                          }{" "}
                          <br />
                          <span
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <b>Volunteers</b>{" "}
                            <AddVolunteerDialog
                              selectedPosition={selectedPosition}
                              volunteerSchedule={volunteerSchedule}
                              setVolunteerSchedule={setVolunteerSchedule}
                              indexOfQuarter={i}
                              date={date}
                              shift={shift}
                              volunteerList={volunteerList}
                              setVolunteerList={setVolunteerList}
                              volunteerLoading={volunteerLoading}
                            />
                          </span>
                          {displayedScheduleQuarter[date][selectedPosition][
                            shift
                          ].volunteers.map((volunteer, index) => (
                            <span
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "baseline",
                              }}
                            >
                              <VolunteerInfoDialog
                                volunteer={volunteer}
                                creditedVolunteers={
                                  displayedScheduleQuarter[date][
                                    selectedPosition
                                  ][shift].enteredCodes
                                }
                              />{" "}
                              <Button
                                onClick={() =>
                                  RemoveVolunteerFromShift(
                                    volunteer,
                                    selectedPosition,
                                    volunteerSchedule,
                                    setVolunteerSchedule,
                                    i,
                                    date,
                                    shift,
                                    index,
                                  )
                                }
                                color="secondary"
                              >
                                REMOVE
                              </Button>
                            </span>
                          ))}
                        </div>
                      </React.Fragment>
                    ))}
                </span>
              </div>
            ))}
        </>
      ))}
    </VolunteerScheduleContext.Provider>
  );
}
