// By clicking the details button on the PaymentHistoryByDateDetailsDialog then you can see
// the details of each community member's payment transaction history on this date.
import React from "react";
import { Grid } from "@material-ui/core";
import FindNextDay from "../../../functions/FindNextDay.js";
import YYYYMMDDConverter from "../../../functions/YYYYMMDDConverter.js";

export default function UserPaymentByDateDetails({
  paymentDetails,
  user,
  moneyDepositDate,
}) {
  // Split the user so we can display their name.
  const name = user.split("-");

  // This is the year the order was placed YYYY
  const year = name[3].substring(0, 4);
  // This is the month the order was placed MM
  const month = name[3].substring(4, 6);
  // This is the day the order was placed DD
  const day = name[3].substring(6, 8);
  // The date that the transaction occured.
  const distributionDate = month + "-" + day + "-" + year;

  // Check the deposits is an array in case it is still stored as a dictionary
  if (!Array.isArray(paymentDetails.deposits)) {
    paymentDetails.deposits = [];
  }
  // Check the orderRefunds is an array in case it is still stored as a dictionary
  if (!Array.isArray(paymentDetails.orderRefunds)) {
    paymentDetails.orderRefunds = [];
  }

  // These are the deposits to display as a user may have other deposits
  // that occured from this order but for other days.  We don't want to show those extra
  // amounts.
  const depositsToDisplay = [];

  // Cycle through the deposits.
  paymentDetails.deposits.forEach((deposit) => {
    // Check the date of the deposit.
    let depositDate = new Date(deposit.date);
    // Find the next Saturday after the deposit.
    depositDate = FindNextDay(depositDate, 6);
    // Convert to our YYYYMMDD format.
    const depositDateYYYYMMDD = YYYYMMDDConverter(depositDate);
    // Check if the deposit date was the same as the transaction we are looking at.
    // If it is then we want to display it otherwise ignore it.
    if (depositDateYYYYMMDD === moneyDepositDate) {
      depositsToDisplay.push(deposit);
    }
  });

  // These are the refunds to display as a user may have other refunds
  // that occured from this order but for other days.  We don't want to show those extra
  // amounts.
  const refundsToDisplay = [];

  // Cycle through the refunds.
  paymentDetails.orderRefunds.forEach((refund) => {
    // Check the date of the refund.
    let refundDate = new Date(refund.date);
    // Find the next Saturday after the refund.
    refundDate = FindNextDay(refundDate, 6);
    // Convert to our YYYYMMDD format.
    const refundDateYYYYMMDD = YYYYMMDDConverter(refundDate);
    // Check if the refund date was the same as the transaction we are looking at.
    // If it is then we want to display it otherwise ignore it.
    if (refundDateYYYYMMDD === moneyDepositDate) {
      refundsToDisplay.push(refund);
    }
  });

  return (
    <div style={{ borderBottom: "double", marginBottom: "5px" }}>
      {depositsToDisplay.map((deposit) => (
        <>
          {deposit.amount > 0.01 && (
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={1}
              style={{ textAlign: "center", marginBottom: "5px" }}
            >
              <Grid item xs>
                {name[0]} {name[1]}
              </Grid>
              <Grid item xs>
                ${deposit.amount.toFixed(2)}
              </Grid>
              <Grid item xs>
                {deposit.date}
              </Grid>
              <Grid item xs>
                {distributionDate}
              </Grid>
              <Grid item xs>
                {deposit.method}
              </Grid>
            </Grid>
          )}
        </>
      ))}
      {refundsToDisplay.map((refund) => (
        <>
          {refund.amount > 0.01 && (
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={1}
              style={{ textAlign: "center", marginBottom: "5px" }}
            >
              <Grid item xs>
                {name[0]} {name[1]}
              </Grid>
              <Grid item xs>
                ${refund.amount.toFixed(2)}
              </Grid>
              <Grid item xs>
                {refund.date}
              </Grid>
              <Grid item xs>
                {distributionDate}
              </Grid>
              <Grid item xs>
                Refund
              </Grid>
            </Grid>
          )}
        </>
      ))}
    </div>
  );
}
