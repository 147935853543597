// This adds a users credits to their profile.  It will read their current credits
// from the database and add their total.  If they don't have any credits then
// it will set their current credits to the amount being added.  It will then update
// the userInfo so that it is loaded properly.
import firebase from "../../../../components/Firebase.js";

export default function AddUserCredits(
  creditsToAdd,
  userId,
  handleUserInfoChange,
) {
  // set up the datebase.
  const database = firebase.firestore();

  // Find the document where this order date is saved.
  const userDocRef = database.collection("Users").doc(userId);

  // Pull the document for reading.
  userDocRef
    .set(
      {
        credits: firebase.firestore.FieldValue.increment(creditsToAdd),
      },
      { merge: true }, // Ensures other fields in the document are not overwritten.
    )
    .then(async () => {
      console.log("Credits updated successfully!");

      // Optionally retrieve the updated document to update the local state.
      if (handleUserInfoChange) {
        const updatedDoc = await userDocRef.get();
        if (updatedDoc.exists) {
          handleUserInfoChange(updatedDoc.data(), false, false, true);
        }
      }
    })
    .catch((error) => {
      console.error("Error updating user credits:", error);
      alert(`Failed to update user credits: ${error.message}`);
    });
}
