// This is B2/3 dialogs used by admin user's where the user can select
// which volunteers to send credits to. The users will then have their credits
// added to their account and an email is sent to them letting them know this.
import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { VolunteerScheduleContext } from "../../../../pages/MyAccountPage/Components/VolunteerSchedule/VolunteerScheduleList.jsx";

export default function UserSelectionCreditsDialog({
  userEmails,
  openUserSelectionCreditsDialog,
  setOpenUserSelectionCreditsDialog,
  setStateOfDialog,
  checkList,
  setCheckList,
  greeting,
  setGreeting,
  message,
  setMessage,
  nextState,
  date,
  timePeriod,
  userIdList,
  setUserIdList,
}) {
  // The volunteer schedule is an array with each Quarter at each index.
  // Then it is a dictionary of all the dates. The selected position is a
  // string of the current position.
  const { volunteerSchedule, selectedPosition } = useContext(
    VolunteerScheduleContext,
  );

  // Finds the index of the volunteer schedule that has the timeperiod
  // of the chosen date for the day the volunteers are being emailed.
  const volunteerScheduleIndex = volunteerSchedule.findIndex(
    (entry) => entry.timePeriod === timePeriod,
  );

  // If the user closes this dialog then reset the state, checkList and close the dialog.
  const handleClose = () => {
    setOpenUserSelectionCreditsDialog(false);
    setStateOfDialog("selectAction");
    setCheckList({});
  };

  // If the user moves to the next state update it.
  const handleNext = (value) => {
    setOpenUserSelectionCreditsDialog(false);
    setStateOfDialog(value);
  };

  // When the user changes the checklist
  const handleChange = (e, shift) => {
    const target = e.target;
    setCheckList({ ...checkList, [target.name]: target.checked });
    if (setUserIdList) {
      const userIdListTemp = [...userIdList];
      if (target.checked) {
        userIdListTemp.push({ [target.name.split("-")[2]]: shift });
      } else {
        const userIdListIndex = userIdListTemp.findIndex(
          (userShift) => userShift[target.name.split("-")[2]] === shift,
        );
        userIdListTemp.splice(userIdListIndex, 1);
      }
      setUserIdList([...userIdListTemp]);
    }
  };

  // When the user changes the greeting option.
  const handleGreetingChange = (e) => {
    // eslint-disable-next-line no-unused-vars
    const target = e.target;
    setGreeting(e.target.value);
  };

  // When the user changes the customized message.
  const handleMessageChange = (e) => {
    let value = e.target.value;
    if (value.slice(-1) === "\n") {
      value = value.slice(0, -1);
      value = value.concat("<br/>");
    }
    setMessage(value);
  };

  // Since we don't want the user moving to the next state until they've selected
  // users and created a message then we just check to make sure those conditions are
  // met and then set this variable to true which displays the next button.  If the user
  // is sending credits then we can go forward with an empty message.
  let nextButtonVisible = false;
  if (message !== "" || nextState === "ConfirmCreditsSend") {
    Object.keys(checkList).forEach((user) => {
      if (checkList[user]) {
        nextButtonVisible = true;
      }
    });
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={openUserSelectionCreditsDialog}
      >
        <DialogTitle id="simple-dialog-title">
          Select the Users to Send Credits and Emails
        </DialogTitle>
        <DialogContent>
          <List>
            <FormLabel component="legend">Select Users to Email</FormLabel>
            <FormGroup>
              {Object.keys(userEmails).map((shift, ind) => (
                <React.Fragment key={ind}>
                  <h6>
                    <u>{shift}</u>
                  </h6>
                  {Object.keys(userEmails[shift]).map((user, i) => (
                    <FormControlLabel
                      key={i}
                      control={
                        <Checkbox
                          name={user}
                          value={checkList[user]}
                          onChange={(e) => handleChange(e, shift)}
                          disabled={volunteerSchedule?.[
                            volunteerScheduleIndex
                          ]?.[date]?.[selectedPosition]?.[
                            shift
                          ]?.enteredCodes?.includes(user.split("-")[2])}
                        />
                      }
                      label={[
                        user.split("-")[0],
                        user.split("-")[1],
                        ":",
                        userEmails[shift][user],
                      ].join(" ")}
                    />
                  ))}
                </React.Fragment>
              ))}
            </FormGroup>
          </List>
          <FormLabel component="legend">Select a Greeting</FormLabel>
          <RadioGroup
            aria-label="greeting"
            name="greeting"
            value={greeting}
            onChange={handleGreetingChange}
          >
            <FormControlLabel
              value="Dear"
              control={<Radio />}
              label="Dear <First Name>"
            />
            <FormControlLabel
              value="Hi"
              control={<Radio />}
              label="Hi <First Name>"
            />
            <FormControlLabel
              value="Hello"
              control={<Radio />}
              label="Hello <First Name>"
            />
          </RadioGroup>
          <TextField
            id="outlined-multiline-static"
            label="Custom Message"
            multiline
            minRows={4}
            placeholder="Put your custom message here."
            variant="outlined"
            value={message}
            onChange={handleMessageChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            CLOSE
          </Button>
          {nextButtonVisible && (
            <Button color="primary" onClick={() => handleNext(nextState)}>
              NEXT
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
