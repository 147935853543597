// This is B3/3 dialogs used by admin to send volunteers an
// email to let them know their credits have been added.
import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import firebase from "../../../Firebase.js";
import { VolunteerScheduleContext } from "../../../../pages/MyAccountPage/Components/VolunteerSchedule/VolunteerScheduleList.jsx";
import batchEmails from "../../../../functions/Email/BatchEmailer.js";
import UpdateCredits from "./functions/UpdateCredits.js";

// This function is used to send in to the email batcher to help send off
// the emails.
export async function CustomCreditsMessageEmails(item) {
  const [
    userEmailsNoLocation,
    user,
    setSendingEmails,
    greeting,
    message,
    creditsMessage,
  ] = item;

  try {
    // Sets the email cloud function
    const customMessageToUsers = firebase
      .functions()
      .httpsCallable("customMessageToUsers");

    // Sets the destination of the email to the user's email.
    const dest = [userEmailsNoLocation[user]];
    // Set the message to send which is normally just the message written.
    let messageToSend = message;
    // Check to see if message is empty or not as we will have to combine them.
    // and make sure that the breaks are correct.
    if (message !== "") {
      messageToSend = [message, creditsMessage[user]].join("<br/><br/>");
    } else {
      messageToSend = creditsMessage[user];
    }
    // Sets this to true to display the loading dialog while emails are sent
    setSendingEmails(true);
    // calls the cloud function
    await customMessageToUsers({
      dest,
      subject: "A Message from Farm-to-Plate Marketplace",
      greeting,
      firstName: user.split("-")[0],
      customMessage: messageToSend,
    });
  } catch (error) {
    // Getting the Error details.
    const message = error.message;
    // Throw the error to propagate it
    throw new Error(`Failed to send email to ${user}: ${message}`);
  }
}

export default function ConfirmCreditsMessageDialog({
  openConfirmCreditsMessageDialog,
  setOpenConfirmCreditsMessageDialog,
  setStateOfDialog,
  userEmails,
  checkList,
  setCheckList,
  greeting,
  message,
  setSendingEmails,
  nextState,
  creditsMessage,
  date,
  timePeriod,
  userIdList,
  setUserIdList,
}) {
  // If the user hits the back button then it will clear their checkList and set the
  // the current state (value) to "Custom" where they will write their message and
  // choose who to email.
  const handleBack = (value) => {
    setOpenConfirmCreditsMessageDialog(false);
    setStateOfDialog(value);
    setCheckList({});
  };
  // If the user close the dialog then reset the state of this dialog series to
  // "selectAction" and clear the checkList.
  const handleClose = () => {
    setOpenConfirmCreditsMessageDialog(false);
    setCheckList({});
    setStateOfDialog("selectAction");
  };

  // The volunteerSchedule is all the different quarters in an array and the selected postition
  // is a string for the current position.
  const { volunteerSchedule, setVolunteerSchedule, selectedPosition } =
    useContext(VolunteerScheduleContext);

  // Since we have the imported orders the userEmails is a dictionary like this
  // {location : {user} : email}.  We don't need the location on this dialog so this
  // is a dictionary with just the {user : email}
  const userEmailsNoLocation = {};

  // Cycle through the userEmails to remove the locations and just have the {user : email}
  Object.keys(userEmails).forEach((location) => {
    Object.keys(userEmails[location]).forEach((user) => {
      userEmailsNoLocation[user] = userEmails[location][user];
    });
  });

  // If the user confirms they wish to send the emails.
  const handleConfirm = async () => {
    // Creates the batch of emails to send off.
    const emailBatch = [];
    // Create an object of the checklist to cycle through.
    const users = Object.keys(checkList);
    // Cycle through the checkList that the user has selected.
    for (const user of users) {
      // Checks to see if the user has been checked
      if (checkList[user]) {
        emailBatch.push([
          userEmailsNoLocation,
          user,
          setSendingEmails,
          greeting,
          message,
          creditsMessage,
        ]);
      }
    }

    // This is to update the user's credits.
    UpdateCredits(
      userIdList,
      volunteerSchedule,
      setVolunteerSchedule,
      selectedPosition,
      date,
      timePeriod,
    );

    // Call the batch email function that will handle the delays necessary
    // to sending the emails off without overloading the send mail function.
    await batchEmails({
      batchedParamsForEmailFunc: emailBatch,
      singularEmailFunc: CustomCreditsMessageEmails,
    });
    setUserIdList([]);
    // Close the dialog
    setOpenConfirmCreditsMessageDialog(false);
    // Reset the checklist.
    setCheckList({});
    // reset the state.
    setStateOfDialog("selectAction");
    // Close the sending emails progress dialog
    setSendingEmails(false);
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={openConfirmCreditsMessageDialog}
      >
        <DialogTitle id="simple-dialog-title">
          Confirm Credits and Emails
        </DialogTitle>
        <DialogContent>
          <List>
            <h6>
              <u>Users being emailed</u>
            </h6>
            {Object.keys(checkList).map((user, i) => (
              <span key={i}>
                {checkList[user] && (
                  <ListItem autoFocus>
                    <ListItemText
                      primary={[
                        user.split("-")[0],
                        user.split("-")[1],
                        ":",
                        userEmailsNoLocation[user],
                      ].join(" ")}
                    />
                  </ListItem>
                )}
              </span>
            ))}
          </List>

          <h6>
            <u>Custom Message</u>
          </h6>
          <p>
            {greeting} "First Name",
            <br />
            {message}
          </p>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => handleBack(nextState)}>
            BACK
          </Button>
          <Button color="primary" onClick={() => handleConfirm()}>
            SEND
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
