// This is 3/3 dialogs used by distribution locations to send their customers a
// custom email.   It displays the email message, the users and the greeting and
// asks the distribution location to confirm before sending out the emails.
import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import firebase from "../../../../components/Firebase.js";
import batchEmails from "../../../../functions/Email/BatchEmailer.js";

// This function is used to send in to the email batcher to help send off
// the emails.
async function CustomMessageEmails(item) {
  const [userEmailsNoLocation, user, setSendingEmails, greeting, message] =
    item;

  try {
    // Sets the email cloud function
    const customMessageToUsers = firebase
      .functions()
      .httpsCallable("customMessageToUsers");

    // Sets the destination of the email to the user's email.
    const dest = [userEmailsNoLocation[user]];
    // Sets this to true to display the loading dialog while emails are sent
    setSendingEmails(true);
    // calls the cloud function
    await customMessageToUsers({
      dest,
      subject: "A Message from Farm-to-Plate Marketplace",
      greeting,
      firstName: user.split("-")[0],
      customMessage: message,
    });
  } catch (error) {
    // Getting the Error details.
    const message = error.message;
    // Throw the error to propagate it
    throw new Error(`Failed to send email to ${user}: ${message}`);
  }
}

export default function ConfirmCustomMessageDialog({
  openConfirmCustomMessageDialog,
  setOpenConfirmCustomMessageDialog,
  setStateOfDialog,
  userEmails,
  checkList,
  setCheckList,
  greeting,
  message,
  setSendingEmails,
  nextState,
}) {
  // If the user hits the back button then it will clear their checkList and set the
  // the current state (value) to "Custom" where they will write their message and
  // choose who to email.
  const handleBack = (value) => {
    setOpenConfirmCustomMessageDialog(false);
    setStateOfDialog(value);
    setCheckList({});
  };
  // If the user close the dialog then reset the state of this dialog series to
  // "selectAction" and clear the checkList.
  const handleClose = () => {
    setOpenConfirmCustomMessageDialog(false);
    setCheckList({});
    setStateOfDialog("selectAction");
  };

  // Since we have the imported orders the userEmails is a dictionary like this
  // {location : {user} : email}.  We don't need the location on this dialog so this
  // is a dictionary with just the {user : email}
  const userEmailsNoLocation = {};

  // Cycle through the userEmails to remove the locations and just have the {user : email}
  Object.keys(userEmails).forEach((location) => {
    Object.keys(userEmails[location]).forEach((user) => {
      userEmailsNoLocation[user] = userEmails[location][user];
    });
  });

  // If the user confirms they wish to send the emails.
  const handleConfirm = async () => {
    // Creates the batch of emails to send off.
    const emailBatch = [];
    // Create an object of the checklist to cycle through.
    const users = Object.keys(checkList);
    // Cycle through the checkList that the user has selected.
    for (const user of users) {
      // Checks to see if the user has been checked
      if (checkList[user]) {
        emailBatch.push([
          userEmailsNoLocation,
          user,
          setSendingEmails,
          greeting,
          message,
        ]);
      }
    }
    // Call the batch email function that will handle the delays necessary
    // to sending the emails off without overloading the send mail function.
    await batchEmails({
      batchedParamsForEmailFunc: emailBatch,
      singularEmailFunc: CustomMessageEmails,
    });

    // Close the dialog
    setOpenConfirmCustomMessageDialog(false);
    // Reset the checklist.
    setCheckList({});
    // reset the state.
    setStateOfDialog("selectAction");
    // Close the sending emails progress dialog
    setSendingEmails(false);
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={openConfirmCustomMessageDialog}
      >
        <DialogTitle id="simple-dialog-title">Confirm Emails</DialogTitle>
        <DialogContent>
          <List>
            <h6>
              <u>Users being emailed</u>
            </h6>
            {Object.keys(checkList).map((user, i) => (
              <span key={i}>
                {checkList[user] && (
                  <ListItem autoFocus>
                    <ListItemText
                      primary={[
                        user.split("-")[0],
                        user.split("-")[1],
                        ":",
                        userEmailsNoLocation[user],
                      ].join(" ")}
                    />
                  </ListItem>
                )}
              </span>
            ))}
          </List>

          <h6>
            <u>Custom Message</u>
          </h6>
          <p>
            {greeting} "First Name",
            <br />
            {message}
          </p>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => handleBack(nextState)}>
            BACK
          </Button>
          <Button color="primary" onClick={() => handleConfirm()}>
            SEND
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
