// The How it works page is to go in to detail about the inner workings
// of the program pillars to help the community better understand the program.
import React, { useLayoutEffect } from "react";
import Menu from "../components/Menu.js";
import "../styles/HowItWorks.css";
import Footer from "../components/Footer.jsx";
import ProgramPillars from "./HowItWorksStepsPage/ProgramPillars.jsx";
export default function HowItWorks() {
  // The details of each step.
  const pillarDetails = [
    {
      title: "Farmers Collect Cost of Food",
      src: "https://lh3.googleusercontent.com/pw/AP1GczPfVQu8ZM2qO0qXjfA2TQ6v0Y5JRRxGynB7gbDVHtGF6f2AD7xEZds8bWqyNj20Tv5XgOekeaHnA6s_Un1MGGrRk0-zHvOG_EGZyafuYLzF_hZR9jxnAc2EAG7n9ZGkdxmYWsoYHSnh6lINtXdLLSTo=w247-h196-s-no-gm?authuser=1",
      text: `At Farm-to-Plate Marketplace, farmers set the price of their food, ensuring that the money from the sales goes directly to them. This model is essential because it allows farmers to control all the funds that enter the marketplace, eliminating the need for a middleman. By maintaining control over the sales process, farmers are empowered to reinvest resources back into the system, giving them ownership and stewardship over the entire model.
            \nFarmers have the autonomy to hire services to administer work, but they remain in control of the cost of selling their food. This contrasts sharply with selling produce to a reseller, which disconnects farmers from the sale of the product to the end user.
            \nWhen consumers buy directly from farmers online, the money they spend on the cost of food is the money that the farmers receive. However, there are other fees collected by Farm-to-Plate Marketplace to support the platform.
            \nWe ensure a direct communication link between farmers and consumers. This link is typically via email or phone, listed under our Farmers page, which contains a list of all our farmers and their contact details. While not all farmers share their phone numbers, they are reachable through the provided contact information. This open line of communication fosters trust and transparency between farmers and consumers, enhancing the overall experience and satisfaction within our marketplace.`,
    },
    {
      title: "Universality",
      src: "https://lh3.googleusercontent.com/pw/AP1GczPL8PhbEeCk-jsy15r_KmG3rQDfnrVlGIEtZPz4aPiNJ0uGVdko2kUmxKY5vf5k1XoXsd9y7UGMAhOxVOJcOCOmzcAJPFudcoI5Fp8Cbj_qIGrw0XgdTY-TiHYKRoTgrfe_1tK9slYBEUxMZfCdYsIJ=w171-h171-s-no-gm?authuser=1",
      text: (
        <div>
          <p>
            At Farm-to-Plate Marketplace, we believe in designing systems that
            foster inclusivity rather than alienation or isolation.
          </p>
          <p>
            <u>Our Goal</u>
            <br />
            Our goal is to create a food system that includes people who are
            food insecure, not that is only for them. We aim to provide the
            necessary supports to lower barriers to entry. This ensures that
            everyone participates in the same food system rather than
            segregating individuals into isolated food systems based on their
            circumstances. We strive to foster inclusivity and accessibility for
            all.
          </p>
          <p>
            We acknowledge the existence of various food programs and strive to
            support them whenever possible, integrating ourselves into a larger
            food system. Community initiatives like community fridges, communal
            meals, and food banks can also request food from our program, and we
            offer them as much food as possible.
          </p>
          <strong>Four Community Member Categories:</strong>
          <ol>
            <li>
              Highly Food Insecure Individuals: These community members strictly
              access our free rescued food and buy food that they can cover
              fully with their subsidy. These people rarely spend any of their
              own money.
            </li>
            <li>
              Food Insecure Individuals with Limited Resources: These community
              members have some financial means, but not enough to pay full
              price. We aim to help them participate by providing free rescued
              food and a subsidy. Then they can spend their food money budget on
              supporting our local food system.
            </li>
            <li>
              Food Secure Individuals: Some community members may be food secure
              but face challenges in affording and accessing local food due to
              high costs and logistical barriers. These users are able to access
              rescued free items that help bring down their overall bill to help
              them spend their money on supporting the local food system.
            </li>
            <li>
              Financially Stable and Food Secure Individuals: Lastly, we aim to
              engage community members who are financially stable and food
              secure in supporting their neighbours and the broader community.
              Through donations and support, we encourage these individuals to
              contribute to initiatives that help others access the same
              high-quality food that they enjoy. These users can take free
              rescued food and leave a larger donation. This promotes choice for
              the users 1 and 2 to buy what they want rather than take what is
              free.
            </li>
          </ol>
          <p>
            By recognizing and catering to the diverse needs of our community
            members, we strive to create a food system that is truly inclusive
            and supportive for all. Together, we work towards ensuring that
            everyone can order healthy and locally sourced food, regardless of
            their circumstances.
          </p>
        </div>
      ),
    },
    {
      title: "Pay What You Can",
      src: "https://lh3.googleusercontent.com/pw/AP1GczPyXZbQ0qpLUDoNO6xkZo-RIKbDyVzKezmSCZJVq3sh7EhYESwDhLUizpSXQybOHnK1IGQfYC6QM2K7-nEvypE9SLEA43ULhTK7T0fVuOz7_uNjNY6cCt-EzT88GYoSmw66JJgl-x6ZCj5_7nqq9l7v=w171-h171-s-no-gm?authuser=1",
      text: (
        <div>
          Farm-to-Plate uses a pay-what-you-can model to support community
          members in purchasing their groceries. Here's how it works:
          <ol>
            <li>
              Subsidy for Those in Need: Users who need financial assistance can
              access a subsidy to cover their order.
            </li>
            <li>
              Donations from Food-Secure Users: Users who are food-secure and
              have the financial capacity can offer a donation at checkout.
              These donations provide the funds for the subsidy.
            </li>
          </ol>
          <u>Our Goal</u>
          <br />
          Our aim is to help as many people as possible buy from our local food
          system. By encouraging those who can to give donations, we increase
          our community’s impact on supporting local farmers.
          <br />
          <br />
          <strong>How Donations Help</strong>
          <br />
          When you make a donation, you are essentially giving money to our
          farmers, allowing community members to select items from these vendors
          at an affordable price.
          <br />
          <br />
          <strong>Current Subsidy Program</strong>
          <ul>
            <li>Each user receives a $10.00 subsidy per week.</li>
            <li>
              Our donation contributions currently do not match the amount of
              subsidies we give out.
            </li>
            <li>
              A single donor currently covers this overage through a separate
              fund.
            </li>
          </ul>
          <strong>Program Adjustments</strong>
          <br />
          In March 2024, we found that some users were using the $10.00 subsidy
          without spending any of their own money, then shopping at cheaper
          markets elsewhere. This made our program unsustainable. To address
          this, we made the following changes:
          <ul>
            <li>
              Matching Contribution: For every dollar a user uses in subsidy,
              they must also contribute a dollar of their own. For example, if
              an order costs $20.00, they can use a $10.00 subsidy and pay
              $10.00 themselves.
            </li>
            <li>
              Bi-Weekly Subsidy for Those in Need: Recognizing that some people
              truly have no money, we continue to offer the full subsidy without
              a matched portion every other week.
            </li>
          </ul>
          In September 2024, in response to our adjustment in March some users
          chose to continue only using the subsidy and only ordered biweekly.
          Recognising our program's goal is to help people buy food from local
          producers and that our subsidies were continuing to be unsustainable
          we adjusted our subsidy again with the following changing:
          <ul>
            <li>
              Matching Contribution: For every dollar a user uses in subsidy,
              they must also contribute a dollar of their own. For example, if
              an order costs $20.00, they can use a $10.00 subsidy and pay
              $10.00 themselves.
            </li>
            <li>
              Subsidy for Those in Need: We continue to offer the full subsidy
              without a matched portion every four weeks. That means that a user
              can use all $10.00 in one week or $2.50/week. By spending $7.50 a
              user can access the full $10.00 weekly.
            </li>
          </ul>
          <strong>Rescued Food Program</strong>
          <br />
          We also offer rescued food that is free, and we ask users to make a
          donation for it. You can view more about this by clicking on the
          "Rescued Food" link below.
          <br />
          <br />
          <strong>Impact</strong>
          <br />
          These changes have helped reduce our losses and encourage users to
          support the local food system rather than opting for the cheapest
          options available.
          <br />
          <br />
          <strong>Stay Informed</strong>
          <br />
          If you're curious about our weekly stats, including the number of
          subsidies given versus the donations raised, visit our Community Page
          and check out our Program Data.
        </div>
      ),
    },
    {
      title: "Free Rescued Food",
      src: "https://lh3.googleusercontent.com/pw/AP1GczPSUHWQ1gDUm2u7C6klzy7CP-xe4IcoRQ7_TbgkPFyJOadnk2rszXpt4Fnb2bfl9ks_Qljq-spFmSPiQyX701GMniTsdRWoEsXgfo0FOP83kdw1JJGpDNlwTTKCFF1RXMTdmT__iIBPrWH4zJJF8wa_=w171-h171-s-no-gm?authuser=1",
      text: (
        <div>
          <p>
            In 2021, $8.11 billion worth of food was wasted at the distribution
            and retail stages of the supply chain in Canada, according to the
            Second Harvest Report "Avoidable Food Crisis." This food, although
            edible, was not sold to consumers for various reasons. By rescuing
            this food from landfills and offering it to the community for free,
            we can significantly reduce food waste.
          </p>
          <p>
            Food shouldn't be reserved solely for food-insecure individuals, as
            this can be perceived as offering them food scraps. Sometimes, the
            rescued food may not be of the best quality and might even be fully
            compostable. Offering it exclusively to food-insecure individuals
            essentially designates them as our composters. Instead, this food
            should be made available to everyone, with an option to donate based
            on their ability. This approach helps raise funds that can be added
            to our subsidy pool, providing food-insecure users with more choice
            and dignity.
          </p>
          <strong>Benefits of Free Rescued Food:</strong>
          <ul>
            <li>
              Increased Orders: Users order more food because the overall cost
              of their food is lower.
            </li>
            <li>
              Enhanced Subsidies: Subsidized users can order more because they
              receive free food.
            </li>
            <li>
              Lower Overall Costs: Subsidized users can contribute some money as
              their overall order cost is reduced.
            </li>
            <li>
              Fundraising: Users who order food and then donate help fill the
              subsidy pool, allowing more users to buy food.
            </li>
          </ul>
          <p>
            The more money we raise, the more we can offer in subsidies,
            enabling more users to support local purchases directly from
            farmers.
          </p>
          <strong>Additional Benefits:</strong>
          <ul>
            <li>
              Deals on Food: Food-secure individuals get a deal on their food.
            </li>
            <li>Waste Prevention: They help prevent food waste.</li>
            <li>
              Dignified Support: They help food-insecure individuals obtain food
              in a dignified manner.
            </li>
            <li>
              Local Support: They support local farmers by increasing community
              purchases.
            </li>
          </ul>
          <p>
            Food waste policies are crucial. Often, dealing with food waste
            falls on taxpayers, who either pay for the transport of food to
            landfills or compost facilities or support charity agencies that
            rescue food waste through grants or tax credits, reducing the tax
            pool.
          </p>
          <p>
            <strong>The Cost of Convenience:</strong> While it’s convenient to
            show up, select food from a large pile, and pick the best items,
            this convenience comes with costs. These costs should not fall on
            taxpayers or the environment.
          </p>
        </div>
      ),
    },
    {
      title: "Reduce Food Waste",
      src: "https://lh3.googleusercontent.com/pw/AP1GczMjNR8Xn_Bw3Qnij089En56N7lGUhiTkoCR5xnuTdqw2aaJ_yffoVezVRxBHZt3k56OkZsDYw6LnQOENKgEPJIzVJpPUFatGYaDcblaTBZTRzfB2ntx9LcEaXWqyr5oI-d4wXgHlLBCYMMPTswmiNJz=w168-h168-s-no-gm?authuser=1",
      text: (
        <div>
          <p>
            <strong>Preordering and Crop Planning:</strong>
          </p>
          <ul>
            <li>
              Preordering Food: Helps with crop planning by providing data that
              encourages other farmers to understand community interests and
              available pricing.
            </li>
            <li>
              Reduced Overharvesting: Farmers know exactly what they need to
              bring, reducing overharvesting where food cannot be replanted or
              stored for future use.
            </li>
          </ul>
          <p>
            <strong>Collaboration and Sustainability:</strong>
          </p>
          <ul>
            <li>
              Small-Scale Farming: Often environmentally unsustainable as each
              farm handles its own packaging and deliveries. Collaboration among
              farmers can improve sustainability.
            </li>
            <li>
              Seasonal Eating: Teaching the community to eat locally and
              seasonally can help people adjust their diets throughout the year
              and learn food preservation techniques.
            </li>
            <li>
              Last Mile Delivery: Using Shift Delivery, a bike cooperative, we
              bring food closer to people, reducing the need for them to drive
              to stores for pickup.
            </li>
          </ul>
          <p>
            By implementing these strategies, we can reduce food waste, support
            local farmers, and create a more sustainable and equitable food
            system.
          </p>
        </div>
      ),
    },
    {
      title: "Community Involvement",
      src: "https://lh3.googleusercontent.com/pw/AP1GczP3_VcazIzbakhMvkUTgcRmK80iyyoKFwx-4497fBlqM3CeJL63uOwMtbNNLBq69IeOuMUC-Xd8gEAnw-_jI_ac2RV573i5LaKgHcSCNK67Qg-VwndH2MCP-Mi_NgBORrZHxHL5UCAn5vO_y0csuvXC=w171-h171-s-no-gm?authuser=1",
      text: (
        <div>
          <p>
            <strong>Food Sovereignty:</strong> At Farm-to-Plate Marketplace, we
            embrace the concept of Food Sovereignty. This means we strive for a
            food system in which those who produce, distribute, and consume food
            also control the mechanisms and policies of production and
            distribution.
          </p>
          <p>
            <strong>Empowered Food System:</strong> Our community involvement
            goes beyond mere shopping. It's about empowering individuals to
            shape and govern our food system from the ground up.
          </p>
          <p>
            <strong>Participation Fee:</strong> We charge a 10% participation
            fee on the value of all food. It can be paid with either credits or
            money. This fee is used to hire a staff member to cover gaps missed
            by our community participants.
          </p>
          <p>
            <strong>Earning Credits:</strong> Participation credits are earned
            through volunteering and helping with the program's administrative
            functions. Engaging community members in various roles, such as
            packaging, distribution, and administrative tasks, is essential to
            our model.
          </p>
          <p>
            <strong>Control Through Involvement:</strong> The only way to
            guarantee control in a system is to be involved in running it. Users
            can influence the system by either withdrawing their support or
            offering their support to an alternative group that aligns with
            their wishes.
          </p>
          <p>
            <strong>Flexible Participation Fee:</strong> The participation fee
            can be increased or decreased based on the community's needs. This,
            coupled with credits offered to the community, can be modified as
            determined by community members. For instance, if a community is
            investing in their program, they can issue credits to current users
            while raising the participation fee. This incentivizes new users to
            build up their credits to pay the fee, while existing users can
            manage their costs. The community will determine the fee's level to
            balance encouraging new users and maintaining program investment.
          </p>
          <p>
            <strong>Survey-Driven Decisions:</strong> The community can conduct
            surveys to gather input. Responses are recorded, shared, and linked
            to their anonymous orders. This provides insight into the type of
            users responding to the surveys—whether they are subsidized,
            donating, taking free food, donating for their free food, using
            credits, or paying the cash fee. This data helps tailor the program
            to better meet the community's needs.
          </p>
        </div>
      ),
    },
    {
      title: "Community Hubs For Distribution",
      src: "https://lh3.googleusercontent.com/pw/AP1GczNASzTWimWtFhb0SxFKkp-9Ro8Ht4oKMaH45CEMIdHdzWk6sKZ-eQEKSgAMQw3GMdooRotV4oGEvmd_zfthGiEXZELyQZeTwkUKpoS4an_MKFejPaRJO9i1Wrk_Jc8BUZFahaX4ZKLBzr2sfDKZ8u2e=w190-h96-s-no-gm?authuser=1",
      text: (
        <div>
          <p>
            At Farm-to-Plate Marketplace, we distribute food through Community
            Hubs designed and created by community members just like you. Here’s
            how it works:
          </p>
          <p>
            <strong>How It Works:</strong>
          </p>
          <ul>
            <li>
              <strong>Accessible Distribution Points:</strong> Instead of
              relying solely on traditional grocery stores or markets, we use
              various local spots as distribution points. These can include
              porches, churches, community centers, and coffee shops. Community
              members and their neighbors can pick up their orders from these
              hubs, making it easier to access fresh, local food in a familiar
              and convenient setting.
            </li>
            <li>
              <strong>Community-Designed Hubs:</strong> These distribution
              points are designed and created by local community members,
              ensuring they meet the needs and preferences of the community. If
              you're interested, you can request to create a community hub by
              logging in and heading to My Account -&gt; My Community Location.
              You can also determine if you want your hub to be public or
              privately accessible by only people you have invited.
            </li>
          </ul>
          <p>
            <strong>Sharing Delivery Costs:</strong>
          </p>
          <ul>
            <li>
              <strong>Incentivizing Group Orders:</strong> Each location is
              charged a flat $6.00 delivery fee. That fee is automatically
              divided evenly amongst all community members using that hub. By
              sharing the cost of delivery, we encourage people to order from
              communal spaces rather than opting for individual deliveries. This
              approach reduces delivery costs and promotes the creation of
              shared spaces for group orders.
            </li>
            <li>
              <strong>Fostering Community Participation:</strong> Users are
              encouraged to get their neighbors involved, fostering a sense of
              community and collaboration.
            </li>
          </ul>
          <p>
            <strong>
              Changing the Idea of Food Access - Beyond Grocery Stores:
            </strong>{" "}
            We aim to shift the traditional perception that food can only be
            accessed at grocery stores or markets. Farmers need spaces in our
            cities to drop off food and our Community Hubs provide alternative
            distribution points, making fresh, local food more accessible.
          </p>
          <p>
            By utilizing Community Hubs, we not only make local food more
            accessible but also strengthen community ties and promote
            sustainable practices. Remember, anyone can help create and manage
            these hubs, including you!
          </p>
        </div>
      ),
    },
    {
      title: "Building Community",
      src: "https://lh3.googleusercontent.com/pw/AP1GczNi9i6wvkdcS6ho79USsxaB7eeMFz3TPBpw8-CiMXiiD3C-qv6lXIz2irxJUTd-x04Y8-DO--xBowmxPR3jQukFf5SlytIkZrD5YPB12JRizVTKTF-IaFZhAAPH0tUKkuqo1-d0cNo8UH5gO-P4okNd=w171-h171-s-no-gm?authuser=1",
      text: (
        <div>
          <p>
            <strong>Fostering Connections:</strong> We believe in fostering a
            strong sense of community through various events, including film
            screenings, farm visits, and educational gatherings. These
            activities provide opportunities for neighbors to come together,
            share experiences, and deepen their connections.
          </p>
          <p>
            <strong>Community Hubs:</strong> Our community hubs are carefully
            designed spaces where neighbors gather weekly to collect their food
            orders. These hubs serve as more than just pick-up locations;
            they're meant to be vibrant centers of interaction and engagement.
          </p>
          <p>
            <strong>Supporting Each Other:</strong> We understand that life can
            be unpredictable, which is why we prioritize supporting one another
            within our community. For instance, if a community member is unable
            to pick up their order, fellow members step in to lend a hand.
          </p>
          <p>
            <strong>Skill Development:</strong> We believe in the power of skill
            development and continuous learning. That's why we hope to host more
            workshops and events where community members can develop new skills,
            learn from each other, and contribute to the enrichment of the
            community as a whole. For example, we may host workshops with local
            farmers where participants can not only learn about sustainable
            farming practices but also actively participate in the process.
          </p>
          <p>
            By fostering a sense of belonging, supporting each other, and
            providing opportunities for skill development, we aim to build a
            thriving and interconnected community.
          </p>
        </div>
      ),
    },
    {
      title: "Transparency",
      src: "https://lh3.googleusercontent.com/pw/AP1GczOR6tPNZ4I2HRR-5GtCFIE65vcP2TdZjPSOjst3V8dfTolnUbkyGEy7sMmfS6M-CmSLs7AJR6n4FCwnp-W5yU7MaiEQ6Yoa_TGWfp_TJLg4wvID87eu_TmZqRpjeeo_7pdmbwuxSThcDXl4Lf_nZ3dW=w136-h163-s-no-gm?authuser=1",
      text: (
        <div>
          <p>
            <strong>Promoting Transparency:</strong> At Farm-to-Plate
            Marketplace, transparency is at the core of everything we do. We
            believe in sharing weekly data on our website to provide our
            community members with insight into the inner workings of our
            platform. This transparency not only fosters trust but also empowers
            individuals to make informed decisions. Our goal is to create an
            inclusive and sustainable food system where everyone has a voice and
            can actively participate. By sharing data openly, we invite our
            community members to be part of the solution-finding process,
            contributing to the overall well-being of the community.
          </p>
          <p>
            <strong>The Power of Transparency:</strong> In Open Source Computer
            Programming, there is a Law called Linus’ Law that states, "Given
            enough eyeballs, all bugs are shallow." By being transparent with
            our data and operations, we enable our community members to identify
            issues, brainstorm solutions, and collaborate towards continuous
            improvement. This collaborative approach ensures that everyone has a
            stake in the success of our platform and fosters a culture of
            accountability and innovation. Solutions can only be found when you
            have all the data.
          </p>
          <p>
            Through transparency and data sharing, we empower our community
            members to take ownership of their food system and work together
            towards a more resilient and equitable future.
          </p>
        </div>
      ),
    },
  ];

  // When the page loads make sure it loads at the top of the page or to the place
  // the user should be directed to.
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Menu />
      <div className="How-It-Works-Page" id="home">
        <div className="How-We-Work-Write-Up">
          <div>
            <h1>How We Work</h1>
            <p>
              A central pillar of Farm-to-Plate is community involvement and
              transparency. We believe that a transparent food system, which
              allows for community involvement, will foster and enable
              interested community members to improve their food system and
              motivate support for changes and policy reforms. Below are the
              details of how the program works and why. We hope that by sharing
              this information, we will empower anyone with the drive to
              understand and find new solutions to continue improving their food
              system.
            </p>
          </div>
        </div>

        <div className="HIW-Pillars" id="howDoesItWorkPillar">
          {pillarDetails.map((step, index) => (
            <ProgramPillars
              title={step.title}
              key={index}
              src={step.src}
              text={step.text}
            />
          ))}
          <div style={{ fontSize: "10px", marginLeft: "50px" }}>
            <a
              href="https://www.flaticon.com/free-icons/farmer"
              title="farmer icons"
            >
              Farmer icons created by max.icons;
            </a>
            <a
              style={{ marginLeft: "5px" }}
              href="https://www.flaticon.com/free-icons/baker"
              title="baker icons"
            >
              Baker icons created by afitrose;
            </a>

            <a
              style={{ marginLeft: "5px" }}
              href="https://www.flaticon.com/free-icons/chef"
              title="chef icons"
            >
              Chef icons,
            </a>
            <a
              style={{ marginLeft: "5px" }}
              href="https://www.flaticon.com/free-icons/money"
              title="money icons"
            >
              Money icons, and
            </a>
            <a
              style={{ marginLeft: "5px" }}
              href="https://www.flaticon.com/free-icons/home"
              title="home icons"
            >
              Home icons created by Freepik;
            </a>
            <a
              style={{ marginLeft: "5px" }}
              href="https://www.flaticon.com/free-icons/market"
              title="market icons"
            >
              Market icons created by mynamepong - Flaticon
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
